.tab-background {
  background-color: #e9ebf1;
}
.tab-link {
  border-top: 6px solid #e9ebf1 !important;
  &.active {
    background-color: #f3f5f9;
    border-top: 6px solid #e9ebf1;
  }
  &:hover {
    background-color: #f3f5f9;
    border-top: 6px solid #e9ebf1;
  }
}
