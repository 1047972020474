.container-login {
  border-radius: 5px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  padding: 5rem 2rem 3rem 2rem;
  width: 100%;
  max-width: 380px;

  h3 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    background: #BF2C37;
    border-radius: 5px 5px 0 0;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .form-control {
    border: none;
    border-bottom: 1px solid #ced4da;
    font-size: 0.75rem;
    border-radius: 0;
    padding: 0.5em 0;
    &:required,
    &:focus {
      box-shadow: none;
      border-bottom: 1px solid #ced4da;
    }
    &.filled + .label,
    &:focus + .label {
      transform: translate3d(0, -10px, 0);
      top: 6px;
    }

    &.is-invalid {
      background-position: right calc(0.375em + 1.1875rem) center;
    }
    &.is-invalid ~ .icon-password {
      position: absolute;
      right: 0;
      bottom: 31px;
    }
  }

  .form-group {
    .label {
      color: #717980;
      font-size: 0.75rem;
      font-weight: 600;
      margin: 0;
      position: absolute;
      top: 20px;
      transition: all .3s;
      z-index: 1;
      letter-spacing: 1px;
    }
  }

  .box-remember {
    .btn-link,
    .custom-control-label {
      font-size: 0.75rem;
    }
    .custom-control-label {
      padding-top: 4px;
      padding-left: 0.5rem;
      line-height: 1.2;
      vertical-align: top;
    }
  }

  .btn-access {
    background: #bf3337;
    background: linear-gradient(-45deg, #a62b2c, #bf3337);
    border: none;
    border-radius: 30px;
    color: #fff;
    font-size: 0.9rem;
    padding: 10px 36px;
    text-transform: uppercase;
    background-position: center;
    transition: background 0.8s;
    &:active {
      background-color: #6eb9f7;
      background-size: 100%;
      transition: background 0s;
    }
    position: relative;
    &:before {
      transition: all .2s;
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.2);
    }
    &:focus,
    &:hover {
      box-shadow: none;
      color: #fff;
    }
    &:hover:before {
      width: 100%;
    }
  }

  .icon-password {
    position: absolute;
    right: 0;
    bottom: 0;
    button {
      color: #717980;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

@media screen and (max-height: 470px) {
  .mt-h-470 {
    margin-top: 60px;
  }
}