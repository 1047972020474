.form-control.dark:focus {
  border-color: #ced4da;
  box-shadow: 0 0 0 0.25rem rgba(153, 169, 185, 0.25);
}
.employee-list {
  .table {
    border-collapse: separate;
    border-spacing: 0 1rem;
    thead th {
      padding-left: 1.5rem;
    }
    tbody {
      tr {
        td {
          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          padding: 1.5rem;
          background: #fff;

          .status {
            position: relative;
            padding: 7px;
            display: inline-block;
            width: 12px;
            margin-right: 5px;

            &:before {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              content: '';
              z-index: 1;
              border-radius: 50%;
            }
            &:after {
              border-radius: 50%;
              position: absolute;
              width: 50%;
              height: 50%;
              top: 50%;
              left: 50%;
              transform: translateY(-50%) translateX(-50%);
              content: '';
              z-index: 2;
            }

            &.active:before {
              background: #cbeaa4;
            }
            &.active:after {
              background: #7fcc1d;
            }
            &.inactive:before {
              background: #e8e8ea;
            }
            &.inactive:after {
              background: #c7c8cc;
            }
          }
        }
      }
    }
  }
}