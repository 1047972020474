.header-logo {
  position: absolute;
  top: 10px;
  left: 50px;
  z-index: 12;

  .logo {
    width: 60px;
    height: auto;
  }

  @media screen and (max-width: 500px) {
    .logo {
      display: none;
    }
  }
}