.pagination {
  .page-link.active {
    background-color: #bf2c37;
    border-color: #bf2c37;
    color: #fff !important;
  }
  .page-link:hover,
  .page-link:focus {
    color: #fff;
    border-color: #6c757d;
    background-color: #6c757d;
  }
  .page-link:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.25);
  }
}